<template>
  <img src="../assets/PixPin_2024-07-31_22-00-46.png" class="full-width-img" />
  <nav :style="{ position: isSticky ? 'fixed' : 'relative', top: '0', width: '100%', zIndex: 1000 }">
    <router-link to="/" class="nav-link">CompanyIntro</router-link> | 
    <router-link to="/about" class="nav-link">About</router-link>
  </nav>
  <view class="content" @scroll="handleScroll">
    <router-view />
  </view>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const isSticky = ref(false);

const handleScroll = () => {
  isSticky.value = window.scrollY > 50;
};

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<style scoped>
.full-width-img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0;
  padding: 0;
}

nav {
  background-color: #373a3b;
  color: rgb(243, 236, 236);
  padding: 10px 0;
  margin: 0;
  text-align: center;
  transition: top 0.3s ease;
}

.nav-link {
  color: rgb(243, 236, 236);
  text-decoration: none;
  margin: 0 10px;
}

/* 被选中的 router-link 的默认高亮样式 */
.router-link-active {
  font-weight: bold; /* 设置为粗体 */
  color: rgb(204,162, 88); /* 高亮颜色 */
}

/* 当路由完全匹配时的精确高亮样式 */
.router-link-exact-active {
  color: rgb(204,162, 88); /* 高亮颜色 */
}

.nav-link:hover {
  color:rgb(204,162, 88);
}

.content {
  height: 1500px;
  width: 100%;
}
</style>
