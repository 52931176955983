// src/plugins/i18n.js
import { createI18n } from 'vue-i18n'

// 消息对象，可以根据需要添加更多的语言和消息
const messages = {
  en: {
    companyIntro:"CompanyIntro",
    message: {
      hello: 'hello world'
    }
  },
  zh: {
    companyIntro:"公司介绍",
    message: {
      hello: '你好，世界'
    }
  }
}

// 创建i18n实例
const i18n = createI18n({
  locale: 'zh', // 设置地区
  messages, // 设置地区信息
  // 如果需要，可以在这里添加其他配置，如fallbackLocale
})

export default i18n